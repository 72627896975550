<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Dados gerais"
        active
      >
        <ProviderDetailsGeneralData />
      </b-tab>
      <div v-if="!verifyRouteAddNewItem()">
        <b-tab title="Contatos">
          <ProviderDetailsContacts
            :items="contactsProvider"
            relation="supplier_id"
          />
        </b-tab>
        <b-tab>
          <template #title>
            Anexos
            <b-badge
              variant="primary"
              pill
              class="mr-1"
            >
              {{ attachmentsProvider.length }}
            </b-badge>
          </template>
          <ProviderDetailsAttachments
            :items="attachmentsProvider"
            relation="supplier_id"
          />
        </b-tab>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BBadge } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ProviderDetailsGeneralData from './ProviderDetailsGeneralData.vue'
import ProviderDetailsContacts from '@/components/contacts-details/DetailsContacts.vue'
import ProviderDetailsAttachments from '@/components/attachments-details/DetailsAttachments.vue'

export default {
  components: {
    BTabs,
    BTab,
    BBadge,

    ProviderDetailsGeneralData,
    ProviderDetailsContacts,
    ProviderDetailsAttachments,
  },
  computed: {
    ...mapGetters({ itemData: 'provider/getProviderData', contacts: 'contacts/getContacts', attachments: 'upload/getUpload' }),
    attachmentsProvider() {
      return this.attachments.filter(arr => arr.supplier_id === Number(this.$route.params.id))
    },
    contactsProvider() {
      return this.contacts.filter(arr => arr.supplier_id === Number(this.$route.params.id))
    },
  },
  created() {
    if (!this.verifyRouteAddNewItem()) {
      this.fetchProviderById(this.$route.params.id)
      this.fetchContacts()
      this.fetchUploads()
    }
  },
  methods: {
    ...mapActions({ fetchProviderById: 'provider/fetchProviderById', fetchContacts: 'contacts/fetchContacts', fetchUploads: 'upload/fetchUploads' }),
  },
}
</script>
